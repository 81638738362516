<template>
  <div>
    <spinner-loader :loading="loading" />
    <azkar-category-form
      v-if="!loading"
      :item="azkarCategory"
      :disabled="true"
    />
  </div>
</template>
<script>
import AzkarCategoryForm from '@/common/components/azkar/AzkarCategoryForm.vue'
import AzkarCategory from '@/common/compositions/azkar/AzkarCategoryApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'ViewZikr',
  components: { AzkarCategoryForm, SpinnerLoader },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      azkarCategory, getazkarCategoryRequest, prepareFormData,
    } = AzkarCategory()

    return {
      azkarCategory, getazkarCategoryRequest, prepareFormData,
    }
  },
  created() {
    this.getazkarCategoryRequest(this.$route.params.id).then(res => {
      this.azkarCategory = {
        ...res.data.data,
        icon: [res.data.data.icon],
      }
    }).finally(() => {
      this.loading = false
    })
  },
}
</script>
<style lang="">

</style>
