<template>
  <b-row align-h="center">
    <div
      v-if="loading"
      class="loader"
    />
  </b-row>
</template>
<script>
export default {
  name: 'SpinnerLoader',
  props: {
    loading: { type: Boolean, default: true },
  },
}
</script>
<style lang="">

</style>
