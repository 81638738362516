<template>
  <div>
    <b-card>
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <azkar-inputs
            :item="item"
            :disabled="$attrs.disabled"
          />

          <b-row align-h="between">
            <b-col md="2">
              <back />
            </b-col>

            <b-col
              md="2"
              class="text-right"
            >
              <submit-button
                v-if="!$attrs.disabled"
                v-bind="{handleSubmit,submit}"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

  </div>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import AzkarInputs from './AzkarCategoryInputs.vue'

export default {
  name: 'AzkarCategoryForm',
  components: {
    AzkarInputs, SubmitButton, Back,
  },
  props: {
    item: { type: Object, default: () => {} },
    submit: { type: Function, default: null },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()

    return {
      successfulOperationAlert,
    }
  },
}
</script>
<style lang="">

</style>
