<template>
  <fieldset :disabled="$attrs.disabled">
    <div class="p-1 border mb-2">
      <h4 class="my-1">
        category Details
      </h4>

      <b-form-row>
        <b-col md="6">
          <!-- name en -->
          <text-input
            id="category-name_en"
            name="Name en"
            rules="required|max:200"
            :text.sync="item.name_en"
          />
        </b-col>
        <b-col md="6">
          <!-- name ar -->
          <text-input
            id="zikr-name_ar"
            name="Name ar"
            rules="required|max:200"
            :text.sync="item.name_ar"
          />
        </b-col>
        <!-- parent Category -->
        <b-col
          md="6"
          class="mb-1"
        >
          <azkar-type-select
            rules="required"
            :disabled="$attrs.disabled"
            :type.sync="itemTypeProxy"
            :add-new="true"
          />
        </b-col>
        <b-col
          v-if="($attrs.disabled && item.parent) || (!$attrs.disabled)"
          md="6"
          class="mb-1"
        >
          <azkar-category-select
            ref="category-select"
            rules=""
            :disabled="$attrs.disabled"
            :filter="{type:categoryTypeValue,excludedID:item.id}"
            :category.sync="item.parent"
          />
          <span v-if="!$attrs.disabled">If no Category selected, it will be Considered  Main category</span>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <text-input
            id="zikr-main-cat"
            name="Category"
            rules=""
            text="It is Main Category"
          />
        </b-col>
        <!-- Image -->
        <b-col
          v-if="!item.parent"
          cols="12"
        >
          <upload-files
            id="image"
            :disabled="$attrs.disabled"
            label="Upload Icon"
            rules="ext:svg|size:10240"
            accept=".svg"
            :cropper="false"
            :files.sync="item.image"
          />
          <div>
            <div>
              <images-preview
                :images.sync="item.image"
              />
            </div>
          </div>
          <div
            v-if="!$attrs.disabled"
            class="mb-2"
          >
            <b-form-row class="text-warning mt-2">
              <b-col md="3">
                <li>Supported image files: SVG</li>
              </b-col>
            </b-form-row>
          </div>
        </b-col>
      </b-form-row>
    </div>
  </fieldset>
</template>
<script>
import Helpers from '@/common/compositions/common/Helpers'
import AzkarCategorySelect from '@/common/components/azkar/AzkarCategorySelect.vue'
import AzkarTypeSelect from '@/common/components/azkar/AzkarTypeSelect.vue'
import ImagesPreview from '@/common/components/common/FormInputs/ImagesPreview.vue'
import UploadFiles from '@/common/components/common/FormInputs/UploadFiles.vue'
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  name: 'AzkarInputs',
  components: {
    UploadFiles,
    ImagesPreview,
    TextInput,
    AzkarCategorySelect,
    AzkarTypeSelect,
  },
  props: {
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    itemTypeProxy: {
      get() {
        return this.item.type
      },
      set(value) {
        this.$set(this.item, 'type', value)
        this.$set(this.item, 'parent', null)
        this.$nextTick(() => {
          this.$refs['category-select'].getazkarOptions()
        })
      },
    },
    categoryTypeValue() {
      return this.item.type?.value
    },
    isAddForm() {
      return !this.$route.params.id
    },
  },
  setup() {
    const { isImage } = Helpers()
    return { isImage }
  },
}
</script>
<style lang="scss">

</style>
